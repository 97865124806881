/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { mergeCraftPreview, resolveImage, resolveImages } from "@dataResolvers";
import { Snapshot as PageTemplate } from "@pageTemplates";

export const query = graphql`
  query CountySnapshotQuery {
    craft {
      entry(section: "countySnapshot") {
        title
        url
        slug
        ... on Craft_countySnapshot_countySnapshot_Entry {
          #meta
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          #hero
          heroLabel
          heroTitle
          heroImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          #carousel
          commonCarousel {
            ... on Craft_commonCarousel_carsouelblock_BlockType {
              uid
              carouselTitle
              carouselDescription
              carouselLink {
                url
              }
              carouselImage {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
          }
          #intro
          countySnapBirdHeader
          countySnapBirdDescription
          countySnapBirdImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          #stats
          countySnapStatsHeader
          countySnapBgImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          countySnapStats {
            ... on Craft_stats_stats_Entry {
              id
              statsValue
              statsLabel
            }
          }
          countySnapFeaturedStats {
            ... on Craft_stats_stats_Entry {
              id
              statsValue
              statsLabel
              statsIcon {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
          }
          #industry
          countySnapTenHeader
          countySnapTenDescription
          countySnapTenLogos {
            ... on Craft_companies_sponsors_Entry {
              companyLogo {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
          }
          #cities
          countySnapCitiesHeader
          countySnapCitiesDescription
          countySnapCitiesImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          countySnapCitiesList {
            ... on Craft_countySnapCitiesList_citiesBlock_BlockType {
              citiesHeader
              citiesList {
                ... on Craft_citiesList_TableRow {
                  cityName
                  cityLink
                }
              }
            }
          }
          countyUnincorporatedAreas {
            ... on Craft_countyUnincorporatedAreas_unicorporatedBlock_BlockType {
              unincorporatedHeader
              unincorporatedList {
                name
              }
            }
          }
          #incentives
          countySnapIncentivesHeader
          commonImageCopyBlock {
            ... on Craft_commonImageCopyBlock_imageCopyBlock_BlockType {
              imageCopyBlockTitle
              imageCopyBlockDescription
              imageCopyBlockImage {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              imageCopyBlockCTA {
                text
                url
              }
              imageCopyBlockPDF {
                slug
                status
                title
                url
              }
            }
          }
        }
      }
    }
  }
`;

const previewQuery = `query CountySnapshotQuery {
  craft {
    entry(section: "countySnapshot") {
      title
      url
      slug
      ... on Craft_countySnapshot_countySnapshot_Entry {
        #meta
        metaTitle
        metaDescription
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        #hero
        heroLabel
        heroTitle
        heroImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        #carousel
        commonCarousel {
          ... on Craft_commonCarousel_carsouelblock_BlockType {
            uid
            carouselTitle
            carouselDescription
            carouselLink {
              url
            }
            carouselImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
          }
        }
        #intro
        countySnapBirdHeader
        countySnapBirdDescription
        countySnapBirdImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        #stats
        countySnapStatsHeader
        countySnapBgImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        countySnapStats {
          ... on Craft_stats_stats_Entry {
            id
            statsValue
            statsLabel
          }
        }
        countySnapFeaturedStats {
          ... on Craft_stats_stats_Entry {
            id
            statsValue
            statsLabel
            statsIcon {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
          }
        }
        #industry
        countySnapTenHeader
        countySnapTenDescription
        countySnapTenLogos {
          ... on Craft_companies_sponsors_Entry {
            companyLogo {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
          }
        }
        #cities
        countySnapCitiesHeader
        countySnapCitiesDescription
        countySnapCitiesImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        countySnapCitiesList {
          ... on Craft_countySnapCitiesList_citiesBlock_BlockType {
            citiesHeader
            citiesList {
              ... on Craft_citiesList_TableRow {
                cityName
                cityLink
              }
            }
          }
        }
        countyUnincorporatedAreas {
          ... on Craft_countyUnincorporatedAreas_unicorporatedBlock_BlockType {
            unincorporatedHeader
            unincorporatedList {
              name
            }
          }
        }
        #incentives
        countySnapIncentivesHeader
        commonImageCopyBlock {
          ... on Craft_commonImageCopyBlock_imageCopyBlock_BlockType {
            imageCopyBlockTitle
            imageCopyBlockDescription
            imageCopyBlockImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
            imageCopyBlockCTA {
              text
              url
            }
            imageCopyBlockPDF {
              slug
              status
              title
              url
            }
          }
        }
      }
    }
  }
}`;

const dataResolver = ({ craft }) => {
  const {
    title,
    url,
    slug,
    // meta
    metaTitle,
    metaDescription,
    metaImage,
    // hero
    heroLabel,
    heroTitle,
    heroImage,
    // carousel
    commonCarousel,
    // intro
    countySnapBirdHeader,
    countySnapBirdDescription,
    countySnapBirdImage,
    // stats
    countySnapStatsHeader,
    countySnapBgImage,
    countySnapStats,
    countySnapFeaturedStats,
    // industry
    countySnapTenHeader,
    countySnapTenDescription,
    countySnapTenLogos,
    // cities
    countySnapCitiesHeader,
    countySnapCitiesDescription,
    countySnapCitiesImage,
    countySnapCitiesList,
    countyUnincorporatedAreas,
    // incentives
    countySnapIncentivesHeader,
    commonImageCopyBlock,
  } = craft.entry;

  const resolveStats = stats =>
    stats.map(s => {
      return {
        id: s.id,
        icon: resolveImage(s.statsIcon),
        value: s.statsValue,
        label: s.statsLabel,
      };
    });

  const logos = [];
  countySnapTenLogos.forEach(logo => {
    logos.push(logo.companyLogo[0]);
  });

  const resolveCities = cities =>
    cities.map(c => {
      return {
        name: c.cityName || c.name,
        url: c.cityLink,
      };
    });

  const resolveBlocks = blocks =>
    blocks.map(b => {
      return {
        heading: b.imageCopyBlockTitle,
        copy: b.imageCopyBlockDescription,
        image: resolveImage(b.imageCopyBlockImage),
        link: {
          text: b.imageCopyBlockCTA.text,
          url: b.imageCopyBlockCTA.url,
        },
        pdf: b.imageCopyBlockPDF[0],
      };
    });

  const resolveCarousel = slides =>
    slides.map(s => {
      return {
        uid: s.uid,
        title: s.carouselTitle,
        copy: s.carouselDescription,
        link: {
          url: s.carouselLink.url,
        },
        image: resolveImage(s.carouselImage),
      };
    });

  return {
    title,
    hero: {
      label: heroLabel,
      heading: heroTitle,
      image: resolveImage(heroImage),
    },
    carousel: resolveCarousel(commonCarousel),
    intro: {
      heading: countySnapBirdHeader,
      copy: countySnapBirdDescription,
      image: resolveImage(countySnapBirdImage),
    },
    stats: {
      heading: countySnapStatsHeader,
      image: resolveImage(countySnapBgImage),
      stats: resolveStats(countySnapStats),
      featuredStats: resolveStats(countySnapFeaturedStats),
    },
    industry: {
      heading: countySnapTenHeader,
      copy: countySnapTenDescription,
      images: resolveImages(logos),
    },
    cities: {
      heading: countySnapCitiesHeader,
      copy: countySnapCitiesDescription,
      image: resolveImage(countySnapCitiesImage),
      citiesHeading: countySnapCitiesList[0].citiesHeader,
      cities: resolveCities(countySnapCitiesList[0].citiesList),
      areasHeading: countyUnincorporatedAreas[0].unincorporatedHeader,
      areas: resolveCities(countyUnincorporatedAreas[0].unincorporatedList),
    },
    incentives: {
      heading: countySnapIncentivesHeader,
      blocks: resolveBlocks(commonImageCopyBlock),
    },
    meta: {
      title,
      url,
      slug,
      metaTitle,
      metaDescription,
      metaImage: resolveImage(metaImage || heroImage),
    },
  };
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
    site: process.env.GATSBY_CRAFT_SITE_HANDLE,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
